quick-theme-selection,
language-switch-dropdown,
header-notifications,
chat-toggle-button,
user-menu {
    display: flex;
}

.unread-notification-count {
    position: absolute;
    top: 5px;
    left: 23px;
}

.unread-chat-message-count {
    position: absolute;
    top: -6px;
    left: 13px;
}

p-autoComplete{
    padding: 10px;
}

.menuSearchBarContainer{
    width: calc(100% - 20px);
}
.mobile-subscription-warning{
    background-color: #fff;
}

#kt_wrapper > ng-component {
    display: flex;
    flex: 1;
    flex-direction: column;
}

default-layout{
    display: flex;
    flex: 1;
    flex-direction: column;
}
