@font-face {
  font-family: 'icomoon';
  src:  url('./IcoMoon/icomoon.eot?xrkn6d');
  src:  url('./IcoMoon/icomoon.eot?xrkn6d#iefix') format('embedded-opentype'),
        url('./IcoMoon/icomoon.ttf?xrkn6d') format('truetype'),
        url('./IcoMoon/icomoon.woff?xrkn6d') format('woff'),
        url('./IcoMoon/icomoon.svg?xrkn6d#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

  /* 24/03/2022 PMc - had to change the selector to ds-icon- as it was messing up other things not associated with these fonts */
  /*  [class^="ds-"], [class*=" ds-"] { */
[class^="ds-icon-"], [class*=" ds-icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  /* Mar 31st volund changes */
  line-height: 1.1em;
  font-size: 1.3em; /* changed this from 200% */

  /* Pre-Mar 31st volund changes */
  /*line-height: 0.5em; changed this as it was messing up button text vertical placement */
  /*font-size: 1.7em; changed this from 200% */

  
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ds-icon-default-ab-filter:before {
  content: "\e900";
}
.ds-icon-default-accounting:before {
  content: "\e901";
}
.ds-icon-default-action:before {
  content: "\e902";
}
.ds-icon-default-activity:before {
  content: "\e903";
}
.ds-icon-default-add:before {
  content: "\e904";
}
.ds-icon-default-alert:before {
  content: "\e905";
}
.ds-icon-default-arrow-down:before {
  content: "\e906";
}
.ds-icon-default-arrow-left:before {
  content: "\e907";
}
.ds-icon-default-arrow-right:before {
  content: "\e908";
}
.ds-icon-default-arrow-up:before {
  content: "\e909";
}
.ds-icon-default-badge:before {
  content: "\e90a";
}
.ds-icon-default-badge-tick:before {
  content: "\e90b";
}
.ds-icon-default-bag:before {
  content: "\e90c";
}
.ds-icon-default-bank:before {
  content: "\e90d";
}
.ds-icon-default-barcode:before {
  content: "\e90e";
}
.ds-icon-default-basket:before {
  content: "\e90f";
}
.ds-icon-default-bicycle:before {
  content: "\e910";
}
.ds-icon-default-block:before {
  content: "\e911";
}
.ds-icon-default-blocked:before {
  content: "\e912";
}
.ds-icon-default-blueprint:before {
  content: "\e913";
}
.ds-icon-default-board:before {
  content: "\e914";
}
.ds-icon-default-bolt:before {
  content: "\e915";
}
.ds-icon-default-book:before {
  content: "\e916";
}
.ds-icon-default-book-delete:before {
  content: "\e917";
}
.ds-icon-default-bookmark:before {
  content: "\e918";
}
.ds-icon-default-browser:before {
  content: "\e919";
}
.ds-icon-default-brush:before {
  content: "\e91a";
}
.ds-icon-default-bubble:before {
  content: "\e91b";
}
.ds-icon-default-bug:before {
  content: "\e91c";
}
.ds-icon-default-building:before {
  content: "\e91d";
}
.ds-icon-default-bulb:before {
  content: "\e91e";
}
.ds-icon-default-burger-menu:before {
  content: "\e91f";
}
.ds-icon-default-burger-menu-alt:before {
  content: "\e920";
}
.ds-icon-default-button:before {
  content: "\e921";
}
.ds-icon-default-calculator:before {
  content: "\e922";
}
.ds-icon-default-calendar:before {
  content: "\e923";
}
.ds-icon-default-calendar-add:before {
  content: "\e924";
}
.ds-icon-default-calendar-schedule:before {
  content: "\e925";
}
.ds-icon-default-certificate:before {
  content: "\e926";
}
.ds-icon-default-chat:before {
  content: "\e927";
}
.ds-icon-default-check:before {
  content: "\e928";
}
.ds-icon-default-checklist:before {
  content: "\e929";
}
.ds-icon-default-check-square:before {
  content: "\e92a";
}
.ds-icon-default-cheque:before {
  content: "\e92b";
}
.ds-icon-default-chevron-down:before {
  content: "\e92c";
}
.ds-icon-default-chevron-left:before {
  content: "\e92d";
}
.ds-icon-default-chevron-right:before {
  content: "\e92e";
}
.ds-icon-default-chevron-up:before {
  content: "\e92f";
}
.ds-icon-default-circle:before {
  content: "\e930";
}
.ds-icon-default-circle-add:before {
  content: "\e931";
}
.ds-icon-default-circle-close:before {
  content: "\e932";
}
.ds-icon-default-circle-disable:before {
  content: "\e933";
}
.ds-icon-default-circle-information:before {
  content: "\e934";
}
.ds-icon-default-circle-money:before {
  content: "\e935";
}
.ds-icon-default-circle-question:before {
  content: "\e936";
}
.ds-icon-default-circle-tick:before {
  content: "\e937";
}
.ds-icon-default-clip:before {
  content: "\e938";
}
.ds-icon-default-clipboard:before {
  content: "\e939";
}
.ds-icon-default-clock:before {
  content: "\e93a";
}
.ds-icon-default-close:before {
  content: "\e93b";
}
.ds-icon-default-cloud:before {
  content: "\e93c";
}
.ds-icon-default-cloud-server:before {
  content: "\e93d";
}
.ds-icon-default-cog:before {
  content: "\e93e";
}
.ds-icon-default-collection:before {
  content: "\e93f";
}
.ds-icon-default-colour:before {
  content: "\e940";
}
.ds-icon-default-comment:before {
  content: "\e941";
}
.ds-icon-default-controls:before {
  content: "\e942";
}
.ds-icon-default-crop:before {
  content: "\e943";
}
.ds-icon-default-cursor:before {
  content: "\e944";
}
.ds-icon-default-database:before {
  content: "\e945";
}
.ds-icon-default-dependancy-left:before {
  content: "\e946";
}
.ds-icon-default-dependancy-right:before {
  content: "\e947";
}
.ds-icon-default-desktop-data:before {
  content: "\e948";
}
.ds-icon-default-deviation:before {
  content: "\e949";
}
.ds-icon-default-device-desktop:before {
  content: "\e94a";
}
.ds-icon-default-device-list:before {
  content: "\e94b";
}
.ds-icon-default-device-mobile:before {
  content: "\e94c";
}
.ds-icon-default-device-tablet:before {
  content: "\e94d";
}
.ds-icon-default-document-3d:before {
  content: "\e94e";
}
.ds-icon-default-document-cloud:before {
  content: "\e94f";
}
.ds-icon-default-document-delete:before {
  content: "\e950";
}
.ds-icon-default-document-folder:before {
  content: "\e951";
}
.ds-icon-default-document-graph:before {
  content: "\e952";
}
.ds-icon-default-document-pdf:before {
  content: "\e953";
}
.ds-icon-default-document-png:before {
  content: "\e954";
}
.ds-icon-default-document-signature:before {
  content: "\e955";
}
.ds-icon-default-document-word:before {
  content: "\e956";
}
.ds-icon-default-document-xls:before {
  content: "\e957";
}
.ds-icon-default-document-zip:before {
  content: "\e958";
}
.ds-icon-default-double-chevron-left:before {
  content: "\e959";
}
.ds-icon-default-double-chevron-right:before {
  content: "\e95a";
}
.ds-icon-default-double-cog:before {
  content: "\e95b";
}
.ds-icon-default-download:before {
  content: "\e95c";
}
.ds-icon-default-estimate:before {
  content: "\e95d";
}
.ds-icon-default-estimate-sheet:before {
  content: "\e95e";
}
.ds-icon-default-expand:before {
  content: "\e95f";
}
.ds-icon-default-export:before {
  content: "\e960";
}
.ds-icon-default-file:before {
  content: "\e961";
}
.ds-icon-default-file-block:before {
  content: "\e962";
}
.ds-icon-default-file-chart:before {
  content: "\e963";
}
.ds-icon-default-file-code:before {
  content: "\e964";
}
.ds-icon-default-file-download:before {
  content: "\e965";
}
.ds-icon-default-file-estimate:before {
  content: "\e966";
}
.ds-icon-default-file-upload:before {
  content: "\e967";
}
.ds-icon-default-file-video:before {
  content: "\e968";
}
.ds-icon-default-filter:before {
  content: "\e969";
}
.ds-icon-default-flag:before {
  content: "\e96a";
}
.ds-icon-default-folder:before {
  content: "\e96b";
}
.ds-icon-default-form:before {
  content: "\e96c";
}
.ds-icon-default-gantt:before {
  content: "\e96d";
}
.ds-icon-default-gift:before {
  content: "\e96e";
}
.ds-icon-default-globe:before {
  content: "\e96f";
}
.ds-icon-default-graph:before {
  content: "\e970";
}
.ds-icon-default-graph-bump:before {
  content: "\e971";
}
.ds-icon-default-grid:before {
  content: "\e972";
}
.ds-icon-default-group:before {
  content: "\e973";
}
.ds-icon-default-guage:before {
  content: "\e974";
}
.ds-icon-default-hat:before {
  content: "\e975";
}
.ds-icon-default-heart:before {
  content: "\e976";
}
.ds-icon-default-height:before {
  content: "\e977";
}
.ds-icon-default-help:before {
  content: "\e978";
}
.ds-icon-default-hide:before {
  content: "\e979";
}
.ds-icon-default-history:before {
  content: "\e97a";
}
.ds-icon-default-home:before {
  content: "\e97b";
}
.ds-icon-default-image:before {
  content: "\e97c";
}
.ds-icon-default-industry:before {
  content: "\e97d";
}
.ds-icon-default-infographics:before {
  content: "\e97e";
}
.ds-icon-default-layout:before {
  content: "\e97f";
}
.ds-icon-default-leaf:before {
  content: "\e980";
}
.ds-icon-default-left-end:before {
  content: "\e981";
}
.ds-icon-default-link:before {
  content: "\e982";
}
.ds-icon-default-list:before {
  content: "\e983";
}
.ds-icon-default-lock:before {
  content: "\e984";
}
.ds-icon-default-logout:before {
  content: "\e985";
}
.ds-icon-default-mail:before {
  content: "\e986";
}
.ds-icon-default-mailbox:before {
  content: "\e987";
}
.ds-icon-default-marker:before {
  content: "\e988";
}
.ds-icon-default-measure:before {
  content: "\e989";
}
.ds-icon-default-message:before {
  content: "\e98a";
}
.ds-icon-default-milestone:before {
  content: "\e98b";
}
.ds-icon-default-money:before {
  content: "\e98c";
}
.ds-icon-default-moneypot:before {
  content: "\e98d";
}
.ds-icon-default-moon:before {
  content: "\e98e";
}
.ds-icon-default-move:before {
  content: "\e98f";
}
.ds-icon-default-navigation:before {
  content: "\e990";
}
.ds-icon-default-network:before {
  content: "\e991";
}
.ds-icon-default-notification:before {
  content: "\e992";
}
.ds-icon-default-numerical:before {
  content: "\e993";
}
.ds-icon-default-object-summary:before {
  content: "\e994";
}
.ds-icon-default-open-book:before {
  content: "\e995";
}
.ds-icon-default-org:before {
  content: "\e996";
}
.ds-icon-default-pagination:before {
  content: "\e997";
}
.ds-icon-default-paint:before {
  content: "\e998";
}
.ds-icon-default-palette:before {
  content: "\e999";
}
.ds-icon-default-paper-stack:before {
  content: "\e99a";
}
.ds-icon-default-password:before {
  content: "\e99b";
}
.ds-icon-default-payment-card:before {
  content: "\e99c";
}
.ds-icon-default-pen:before {
  content: "\e99d";
}
.ds-icon-default-phone:before {
  content: "\e99e";
}
.ds-icon-default-pie:before {
  content: "\e99f";
}
.ds-icon-default-power:before {
  content: "\e9a0";
}
.ds-icon-default-present:before {
  content: "\e9a1";
}
.ds-icon-default-presentation:before {
  content: "\e9a2";
}
.ds-icon-default-printer:before {
  content: "\e9a3";
}
.ds-icon-default-process:before {
  content: "\e9a4";
}
.ds-icon-default-puzzle:before {
  content: "\e9a5";
}
.ds-icon-default-qr:before {
  content: "\e9a6";
}
.ds-icon-default-radio:before {
  content: "\e9a7";
}
.ds-icon-default-redo:before {
  content: "\e9a8";
}
.ds-icon-default-refresh:before {
  content: "\e9a9";
}
.ds-icon-default-responsive:before {
  content: "\e9aa";
}
.ds-icon-default-right-end:before {
  content: "\e9ab";
}
.ds-icon-default-robot:before {
  content: "\e9ac";
}
.ds-icon-default-rows:before {
  content: "\e9ad";
}
.ds-icon-default-search:before {
  content: "\e9ae";
}
.ds-icon-default-search-settings:before {
  content: "\e9af";
}
.ds-icon-default-server:before {
  content: "\e9b0";
}
.ds-icon-default-share:before {
  content: "\e9b1";
}
.ds-icon-default-share-social:before {
  content: "\e9b2";
}
.ds-icon-default-shield-star:before {
  content: "\e9b3";
}
.ds-icon-default-sign:before {
  content: "\e9b4";
}
.ds-icon-default-signature:before {
  content: "\e9b5";
}
.ds-icon-default-spanner:before {
  content: "\e9b6";
}
.ds-icon-default-square:before {
  content: "\e9b7";
}
.ds-icon-default-square-add:before {
  content: "\e9b8";
}
.ds-icon-default-stack:before {
  content: "\e9b9";
}
.ds-icon-default-star:before {
  content: "\e9ba";
}
.ds-icon-default-summary:before {
  content: "\e9bb";
}
.ds-icon-default-sun:before {
  content: "\e9bc";
}
.ds-icon-default-table:before {
  content: "\e9bd";
}
.ds-icon-default-tag:before {
  content: "\e9be";
}
.ds-icon-default-target:before {
  content: "\e9bf";
}
.ds-icon-default-task-number:before {
  content: "\e9c0";
}
.ds-icon-default-task-tick:before {
  content: "\e9c1";
}
.ds-icon-default-text:before {
  content: "\e9c2";
}
.ds-icon-default-tile:before {
  content: "\e9c3";
}
.ds-icon-default-tiles:before {
  content: "\e9c4";
}
.ds-icon-default-toggle:before {
  content: "\e9c5";
}
.ds-icon-default-transfer:before {
  content: "\e9c6";
}
.ds-icon-default-trash:before {
  content: "\e9c7";
}
.ds-icon-default-trophy:before {
  content: "\e9c8";
}
.ds-icon-default-undo:before {
  content: "\e9c9";
}
.ds-icon-default-unlock:before {
  content: "\e9ca";
}
.ds-icon-default-upload:before {
  content: "\e9cb";
}
.ds-icon-default-user:before {
  content: "\e9cc";
}
.ds-icon-default-user-add:before {
  content: "\e9cd";
}
.ds-icon-default-user-delete:before {
  content: "\e9ce";
}
.ds-icon-default-user-group:before {
  content: "\e9cf";
}
.ds-icon-default-variable:before {
  content: "\e9d0";
}
.ds-icon-default-verified:before {
  content: "\e9d1";
}
.ds-icon-default-view:before {
  content: "\e9d2";
}
.ds-icon-default-warning:before {
  content: "\e9d3";
}
.ds-icon-default-webinar:before {
  content: "\e9d4";
}
.ds-icon-default-whiteboard:before {
  content: "\e9d5";
}
.ds-icon-default-wifi:before {
  content: "\e9d6";
}
.ds-icon-default-window-code:before {
  content: "\e9d7";
}
.ds-icon-default-window-data:before {
  content: "\e9d8";
}
