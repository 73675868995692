/* NOTE: The ASP.NET Zero framework builds the Angular front-end in the following order:
    1) Bootstrap
    2) Additional ASP.NET Zero styles
    3) Metronic
Since we also want to overlay Volund and Custom styles, these are loaded:
    4) Volund Design System 
    5) Custom styles (this stylesheet document)
*/

// ##########################################################################
// 1) Bootstrap styles are already loaded at this point

// 2) Additional ASP.NET Zero styles:
/* @imports first in order to set styling precedence */
@import '../../node_modules/animate.css/animate.min.css';
@import '../../node_modules/quill/dist/quill.core.css';
@import '../../node_modules/quill/dist/quill.snow.css';
@import '../../node_modules/famfamfam-flags/dist/sprite/famfamfam-flags.css';
@import '../../node_modules/primeng/resources/primeng.min.css';
@import '../../node_modules/primeicons/primeicons.css';
@import '../../node_modules/sweetalert2/dist/sweetalert2.css';
@import '../../node_modules/cookieconsent/build/cookieconsent.min.css';
@import '../assets/primeng/file-upload/css/primeng.file-upload.css';
// @import '../assets/primeng/autocomplete//css/primeng.autocomplete.less';
@import '../assets/primeng/tree/css/primeng.tree.css';
@import '../assets/primeng/context-menu/css/primeng.context-menu.css';
@import '../assets/fonts/fonts-poppins.css';
@import '../assets/fonts/fonts-roboto.css';

// ##########################################################################
// 3) Metronic styles:
@import '../assets/metronic/common/fonts/keenthemes-icons/ki.css';
@import '../assets/ngx-bootstrap/bs-datepicker.css';
@import '../assets/metronic/vendors/global/vendors.bundle.css';
// @import '../app/shared/core.less';
// @import '../app/shared/layout/layout.less';

//below are the files normally dynamically loaded in DynamicResourcesHelper.ts :: loadStyles()
@import '../assets/metronic/themes/default/css/style.bundle.css';
@import '../assets/metronic/themes/default/plugins/global/plugins.bundle.css';
// @import '../assets/metronic/themes/theme5/css/style.bundle.css';
// @import '../assets/metronic/themes/theme5/plugins/global/plugins.bundle.css';
//@import '../assets/primeng/resources/themes/mdc-light-indigo/theme.css'; //this was normally bundled at build time
@import '../../node_modules/primeng/resources/themes/mdc-light-indigo/theme.css';
@import '../assets/primeng/datatable/css/primeng.datatable.css';
@import '../assets/common/styles/metronic-customize.css';
@import '../assets/common/styles/themes/default/metronic-customize.css';
//@import '../assets/common/styles/themes/theme5/metronic-customize.css';
@import '../assets/common/styles/metronic-customize-angular.css';
@import '../assets/fonts/fonts-asap-condensed.css';


// ##########################################################################
// 4) Volund Design System 

// @import '~font-awesome/css/font-awesome.css';
@import '../../node_modules/volund/patterns/dist/styledictionary/scss/variables.scss';
@import '../assets/fonts/fonts-volund.css'; //icomoon = volund (elecosoft) fonts


/* * * * * * * * * * * * * * * * * * * * * * * * */
// declare the main colours used in this project */
//$primary: $colour-powerproject-primary;
//$primary_light: $colour-powerproject-alt; //not used
//$primary_dark: $colour-powerproject-hover;
$primary: $colour-astaconnect-primary; //Coral Red
$primary_dark: $colour-astaconnect-primary-hover;
/* * * * * * * * * * * * * * * * * * * * * * * * */

// The imports below would normally come from here: @import '../node_modules/volund/imports';
// have copied them here to be able to temporarily exclude items for diagnosing issues
@import '../../node_modules/volund/_config.scss';
@import '../../node_modules/volund/libs/_normalize.scss';
@import '../../node_modules/volund/libs/_browsers.scss';
@import '../../node_modules/volund/libs/_buttons.scss';
@import '../../node_modules/volund/libs/_forms.scss';
@import '../../node_modules/volund/libs/_grid.scss';
@import '../../node_modules/volund/libs/_helpers.scss';
@import '../../node_modules/volund/libs/_modals.scss';
@import '../../node_modules/volund/libs/_pagination.scss';
@import '../../node_modules/volund/libs/_type.scss';
// @import '../../node_modules/volund/libs/font-awesome-all';
// @import './partials/font-awesome';
// @import url("https://pro.fontawesome.com/releases/v5.5.0/css/all.css");


// ##########################################################################
// 5) Custom styles:

/* begin: extend bootstrap grid system */
.media-show-xs,
.media-show-sm, 
.media-show-md, 
.media-show-lg, 
.media-show-xl {
    display: none;
}

.media-gauge  {
    z-index: 99999;

    & > .media-show-xs,
    & > .media-show-sm, 
    & > .media-show-md, 
    & > .media-show-lg, 
    & > .media-show-xl {
        width: 40px;
        border: solid 1px gray;
        margin: 2px;
        font-size: 0.8em;
        text-align: center;
    }

    & > .media-show-xs {
        background-color: lemonchiffon;
    }

    & > .media-show-sm {
        background-color: yellow;
    }

    & > .media-show-md {
        background-color: orange;
    }

    & > .media-show-lg {
        background-color: tomato;
        color: white;
    }

    & > .media-show-xl {
        background-color: deepskyblue;
        color: white;
    }
}

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
    .col-xs-0, .col-0 {
        display: none !important;
    }

    .media-show-xs {
        display: inline-block !important;
    }

    .media-hide-xs {
        display: none !important;
    }
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) {
    .col-sm-0 {
        display: none !important;
    }

    .media-show-sm {
        display: inline-block !important;
    }

    .media-hide-sm {
        display: none !important;
    }
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {
    .col-md-0 {
        display: none !important;
    }

    .media-show-md {
        display: inline-block !important;
    }

    .media-hide-md {
        display: none !important;
    }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199.98px) {
    .col-lg-0 {
        display: none !important;
    }

    .media-show-lg {
        display: inline-block !important;
    }

    .media-hide-lg {
        display: none !important;
    }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
    .col-xl-0 {
        display: none !important;
    }

    .media-show-xl {
        display: inline-block !important;
    }

    .media-hide-xl {
        display: none !important;
    }
}
/* end: extend bootstrap grid system */

/* General additional styles */
.rotate-180 {
    transform: rotate(180deg);
}

.rotate-90 {
    transform: rotate(90deg);
}

.rotate-45 {
    transform: rotate(45deg);
}

.noselect {
    user-select: none;
}

.text-right {
    text-align: right;
}

.text-center {
    text-align: center;
}

.text-italic {
    font-style: italic;
}

.text-bold {
    font-weight: bold;
}

.text-red {
    color: red;
}

.text-info {
    color: steelblue !important;
}

.text-ellipsis {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.v-align-top {
    vertical-align: top;
}

.v-align-middle {
    vertical-align: middle;
}

.ty-1 {
    transform: translateY(-1px);
}

.ty-2 {
    transform: translateY(-2px);
}

.d-block {
    display: block;
}

// .d-flex {
//     display: flex;
// }

.nowrap {
    white-space: nowrap;
}

.mt-0 { margin-top: 0 !important; }
.mr-0 { margin-right: 0 !important; }
.mb-0 { margin-bottom: 0 !important; }
.ml-0 { margin-left: 0 !important; }
.margin-left-5 { margin-left: 5px !important; }
.margin-right-5 { margin-right: 5px !important; }
.margin-top-5 { margin-top: 5px !important; }
.margin-bottom-5 { margin-bottom: 5px !important; }
.margin-left-10 { margin-left: 10px !important; }
.margin-right-10 { margin-right: 10px !important; }
.margin-top-10 { margin-top: 10px !important; }
.margin-bottom-10 { margin-bottom: 10px !important; }
.margin-top-15 { margin-top: 15px !important; }
.margin-bottom-15 { margin-bottom: 15px !important; }
.padding-left-5 { padding-left: 5px !important; }
.padding-right-5 { padding-right: 5px !important; }
.padding-top-5 { padding-top: 5px !important; }
.padding-bottom-5 { padding-bottom: 5px !important; }
.width-percent-5 { width: 5%; }
.width-percent-10 { width: 10%; }
.width-percent-15 { width: 15%; }
.width-percent-20 { width: 20%; }
.width-percent-30 { width: 30%; }
.width-percent-40 { width: 40%; }
.width-percent-50 { width: 50%; }
.width-percent-100 { width: 100%; }

/* BEGIN: Toast popups (Sweet alert) */
body.swal2-height-auto {
    height: inherit !important;
}

//.swal2-popup.swal2-toast {
.swal2-popup {
    display: block !important;

    &.swal2-toast {
        padding: 10px 20px !important;
        min-height: 60px;

        .swal2-header,
        .swal2-title,
        .swal2-content {
            text-align: left !important;
            margin: 0 !important;
        }
    }

    .swal2-title {
        font-size: 16px;
        margin-bottom: 4px !important;
    }

    .swal2-html-container {
        font-size: 14px;
        margin: 0;
    }

    .swal2-icon {
        &.swal2-info::before,
        &.swal2-warning::before,
        &.swal2-success::before,
        &.swal2-error::before {
            content: "" !important;
        }
    }

    .swal2-close {
        position: absolute;
        top: 5px;
        right: 5px;
        padding: 0 !important;
        color: #5a5a5a;

        &:hover {
            color: white;
            background-color: #999999;
            border-radius: 50%;
            font-weight: normal;
        }
    }

    .toast-type-info {
        color: white;
    }

    .toast-type-error {
        color: white;
    }

    .toast-type-success {
        color: white;
    }
}
/* END: Toast popups (Sweet alert) */


@keyframes glowing {
    0% {
        opacity: 0.3;
    }

    50% {
        opacity: 1;
    }

    100% {
        opacity: 0.3;
    }
}

.glowing {
    animation: glowing 2s infinite;
}

.root-initial-loader .overlay {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(51, 51, 51, 0.8);
    z-index: 99999 !important;
    width: 100%;
    height: 100%;
}

.root-initial-loader i {
    /* position: absolute;
    color: rgb(91, 167, 234); */
    z-index: 100000 !important;
    /* top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%); */
}

.root-initial-loader .fa-spin-2x {
    -webkit-animation: fa-spin 1s infinite linear;
    animation: fa-spin 1s infinite linear;
}
ngx-spinner .loading-text {
    font-weight: bold;
    color: white;
    font-size: 1.3em;
}

b, strong, .font-bold {
    font-weight: bold;
}

/******************************************************************************/
/* BEGIN: Styling corrections for Bootstrap, Metronic etc AFTER Volund styles */

body {
    font-size: 1rem; //overrides 0.9rem in _types.scss
}

.container-fluid {
    padding: 0 10px;
}

.content {
    padding: 10px 0;
}

/* links */
a {
    color: $dark;
}

/* Volund Icons */
[class^="ds-icon-"], [class*=" ds-icon-"] {
    margin-left: 0.2em;
    margin-right: 0.2em;
}

/* Design System Buttons */
button {
    font-family: "Helvetica Neue", sans-serif;
    font-size: 14px; //16px;
    padding: 8px 14px 6px 14px !important;
    white-space: nowrap;
    border: unset;
    background-color: unset;
    //color: unset;
    color: rgba(0, 0, 0, 0.6);
    
    &.min-width {
        min-width: 100px !important;
    }

    &.ds {
        transition: all ease-in-out 0.2s;
        transition-property: background-color, color, box-shadow, border;
        border-width: 1px !important;
        height: 38px;
        line-height: unset; //override normalize setting this to 1.1
        //box-shadow: 0px 3px 6px #b6b6b6 !important;
        box-shadow: none;

        &:not([disabled]):hover,
        &:not([disabled]):active,
        &:not([disabled]):focus {
            box-shadow: 0px 3px 6px #BFBFBF !important;
        }

        &.light {
            color: black !important;

            i.fa,
            i.pi,
            i[class^="ds-icon-"],
            i[class*=" ds-icon-"] {
                color: black !important;
            }
        }

        &.outlined i.pi {
            transform: translateY(-2px);
        }

        &.outlined.light {
            border-color: $light_hover;

            i.pi,
            i[class^="ds-icon-"],
            i[class*=" ds-icon-"] {
                color: black;
            }
        }

        &.outlined.dark i.pi,
        &.outlined.dark i[class^="ds-icon-"],
        &.outlined.dark i[class*=" ds-icon-"] {
            color: black;
        }
    
        i.fa,
        i.pi,
        i[class^="ds-icon-"],
        i[class*=" ds-icon-"]
        {
            vertical-align: middle;
            color: white;
            // margin-right: -2px;
            position: relative;
            top: -2px;
            transition: color ease-in-out 0.2s;
        }

        i.fa,
        i.pi {
            font-size: 1.1em;
        }

        // i[class^="ds-icon-"],
        // i[class*=" ds-icon-"] {
        //     // font-size: 1.5em;
        // }

        &:is([disabled]) i.fa,
        &:is([disabled]) i.pi,
        &:is([disabled]) i[class^="ds-icon-"],
        &:is([disabled]) i[class*=" ds-icon-"] {
            color: #e2dddd !important;
        }
        

        &.small i.fa,
        &.small i.pi {
            font-size: 0.8em;
        }

        &.small i[class^="ds-icon-"],
        &.small i[class*=" ds-icon-"] {
            font-size: 1.2em;
        }

        /* Design System FAB buttons (the "fab" class collides with font-awesome-brands, so can't use it!) */
        &.ds-fab {
            border-radius: 20px;
            //background-color: $dark;
            line-height: 1.5em;
            
            i[class^="ds-icon-"],
            i[class*=" ds-icon-"] {
                position: relative;
                top: -1px;
                left: -2px;
            }
        }
    }

    &:not([disabled]).ds.outlined.dark:hover {
        
        i.pi,
        i[class^="ds-icon-"],
        i[class*=" ds-icon-"] {
            color: white;
            transition: color ease-in-out 0.2s;
        }
    }
    

    &:disabled {
        color: $light_hover !important;
        background: transparent !important;
        border: 1px solid $light_hover !important;
        cursor: default;

        &:hover {
            //color: $light_hover !important;
            background: transparent !important;
            border: 1px solid $light_hover !important;
        }

        &.light {
            color: $light_hover !important;
        }
    }

    &.search-btn {
        background-color: white;
        border: solid 1px #e4e6ef !important;
        transition: border-color ease-in-out 0.2s;

        i[class^="ds-icon-"],
        i[class*=" ds-icon-"] {
            line-height: 1em;
            padding: 0;
        }

        &:hover {
            background-color: $colour-light-hover;
            i {
                color: black;
            }
        }
    }

    
}

.ds-fab-btn {
    //can't use the class "fab" as it clashes with font-awesome-brands
    display: inline-block;
    width: 38px;
    height: 38px;
    border-radius: 50%;
    background-color: $primary;
    //box-shadow: 0px 3px 6px #b6b6b6 !important;
    //box-shadow: 0 0 6px 3px rgba(0,0,0,0.10);
    box-shadow: 0 0 6px 3px rgb(0 0 0 / 10%);
    text-align: center;
    transition: all 0.15s ease-out;
    transition-property: background-color, border-color, box-shadow;

    &:hover {
        background-color: $primary;
    }

    i {
        font-size: 16px;
        font-size: 1.1em;
        position: relative;
        top: 0.1em;
        left: -0.2em;
        transition: color 0.15s ease-out;
        color: white;
    }

    &.dark {
        background-color: $colour-dark-primary;

        &:hover {
            background-color: #4A4A4A;
        }
    }

    &.light:not(:disabled) {
        color: #262626;
        background-color: white;
        border: solid 1px #C0C0C0;

        &:hover {
            background-color: white;
            border: solid 1px #A0A0A0 !important;
        }

        i {
            color: #262626;
        }
    }
}


/* filter collaps/expand icon */
span.clickable-item i[class^="ds-icon-"],
span.clickable-item i[class*=" ds-icon-"] {
    color: #000000 !important;
    position: relative;
    top: 5px;
}

/* advanced filters row */
.adv-filters {
    background-color: $colour-light-alt;
    border-radius: 7px;
    padding-top: 0.8em;
    margin-bottom: 1em !important;
    margin-top: 0 !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
}

/* compact input fields */
input.form-control.compact {
    font-weight: normal;
    padding: 0.2em 0.6em;
    height: 30px;
}

select.form-control.compact {
    font-weight: normal;
    padding: 0.3em 0.3em;
    height: 30px;
}

option:is([disabled]) {
    color: #C0C0C0 !important;
    text-decoration: line-through !important;
}


/* Bootstrap close button (modals) */
button.btn-close {
    border-radius: 50%;
    padding: 1em;
    width: 40px;
    height: 40px;
    text-align: center;
    box-sizing: border-box;

    &:hover {
        background-color: $light_hover;
        transition: background-color ease-in-out 0.2s;
    }
}


/* Bootstrap toggle (checkbox) */
.form-check {
    display: inline-block;
}

.form-check .form-check-input {
    border: solid 1px #CCCCCC !important;
    
    &:not(:checked) {
        background-color: white !important;
    }

    &:not([disabled]):hover {
        box-shadow: 0px 0px 8px #E9E9E9;
    }

    &:checked {
        background-color: $primary;
    }
}

.form-check.form-switch .form-check-input {
    &:not(:checked) {
        background-color: #EFF2F5 !important;
    }
}

/* Bootstrap form grid rows spacing */
form .row {
    margin-top: 1.5em;
    margin-bottom: 1.5em;

    &:first-child {
        margin-top: 0;
    }

    &:last-child {
        margin-bottom: 0;
    }
}

/* Bootstrap form labels */
.form-label {
    color:$colour-dark-primary;
    font-weight: bold;
    user-select: none;
}

.form-label-inline {
    color:$colour-dark-primary;
    font-size: 1.05rem; //to make same as bootstrap
    font-weight: normal;
    user-select: none;
}

/* Bootstrap input fields */
select.form-control {
    -webkit-appearance: menulist;
}

.form-control:disabled, .form-control[readonly] {
    //background-color: #EFF2F5;
    color: #9A9A9A;
    //opacity: 1;
  }

/* PrimeNG input fields */
.p-inputtext {
    font-size: 1.1rem;
    padding-left: 1rem;
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    padding-right: 1rem;

}

.p-inputnumber-input {
    border-color: #E4E6EF;

    &:hover {
        border-color: #E4E6EF !important;
    }

    &:focus {
        border-color: #B5B5C3 !important;
    }
}

.p-dropdown {
    border: 1px solid #E4E6EF;
    border-radius: 6px;
    height: 40px;

    &:hover {
        border: 1px solid #E4E6EF !important;
    }

    &.p-focus {
        border: 1px solid #B5B5C3 !important;
        box-shadow: none !important;
    }

    // &:focus {
    //     border: 1px solid #B5B5C3 !important;
    // }

    .p-inputtext {
        position: relative;
        top: -1px;
        left: -1px;
        border-right: none;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border-color: #E4E6EF !important;
    }

    &.p-focus .p-inputtext {
        border-color: #B5B5C3 !important;
    }
    
    .p-dropdown-clear-icon {
        margin-top: -0.4rem;
    }
    
    .p-dropdown-trigger .p-dropdown-trigger-icon {
        padding-top: 2px;
    }

    &.p-dropdown-clearable .p-dropdown-label {
        padding-right: 1.75rem;
    }
}

.p-dropdown-panel .p-dropdown-items {
    padding-left: 0 !important;

    .p-dropdown-item {
        font-size: 1.1rem;
        padding: 0.5rem 1rem;

        &.p-focus {
            background: none;

            &:hover {
                color: #000000 !important;
                background-color: #F5F5F5 !important;
            }
        }

        &.p-highlight {
            color: #000000 !important;
            background-color: #D5D5D5 !important;
        }
    }
}

.p-checkbox {

    .p-checkbox-box {
        border-color: $primary;

        .p-checkbox-icon.pi-check {
            position: relative;
            top: -6px;
            left: -7px;
            transform: scale(1.25);
            color: white;
        }

        &.p-highlight {
            border-color: $primary;
            background-color: $primary;

            .p-checkbox-icon.pi-check {
                color: white;
            }
        }
    }

    &.p-checkbox-disabled .p-checkbox-box {
        border-color:#A0A0A0;
        background-color:#A0A0A0;
    }
}

.p-treenode {

    span.p-treenode-label {
        font-family: $ds-fonts !important;
        font-size: 14px !important;
        padding: 2px 0;
        font-weight: normal;
    }

    .p-treenode-content > .p-checkbox {
        margin-right: 5px;
    }

    &.no-checkbox > .p-treenode-content > .p-checkbox {
        display: none;
    }
    
    button.p-tree-toggler {
        padding: 7px !important;

        &:hover {
            background-color: transparent !important;
            border-color: transparent !important;
        }
    }

    &.no-toggler > .p-treenode-content > .p-tree-toggler {
        display: none;
    }

    &.no-checkbox.no-toggler > .p-treenode-content > span.p-treenode-label {
        font-weight: bold;
    }

    .p-treenode.no-toggler > .p-treenode-children > .p-treenode > .p-treenode-content > button.p-tree-toggler {
        //the component sets the visibility of this item to hidden, so that it continues to align with any nodes that have a toggler
        margin: -13px !important;
    }
}

.p-tree {
    border: none !important;
    
    .p-tree-container .p-treenode .p-treenode-content.p-treenode-selectable,
    .p-tree-container .p-treenode .p-treenode-content.p-treenode-selectable.p-highlight {
        background-color: white;

        &:hover {
            background-color: #EFEFEF !important;
        }
    }
}


/* Bootstrap input groups */

// was hoping to fix the problem where the input-group text doesn't share 
// the same border style when the input field is focused, but there's no
// css selector for this!
// .input-group .form-control:focus ~ .input-group-text:focus {
//     border-color: #b5b5c3;
//     transition: border-color 0.15s ease-in-out;
// }

.input-group {
    flex-wrap: nowrap;
    
    & > button {
        height: 40px;
    }

    > span:not(:first-child):not(:last-child) {
        border-top: solid 1px #E4E6EF;
        border-bottom: solid 1px #E4E6EF;
        height: 40px;
    }

    .input-group-text {
        transition: all ease-in-out 0.15s;
        transition-property: border-color, background-color;
        background-color: #EFF2F5;
    }

    input:focus + .input-group-text,
    .p-inputwrapper-focus + .input-group-text {
        border-top: solid 1px #b5b5c3 !important;
        border-right: solid 1px #b5b5c3 !important;
        border-bottom: solid 1px #b5b5c3 !important;
    }

    &.field-left {
        input {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
        }
    }
}

.input-group > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
    margin-left: unset;
}

.form-check .form-check-label {
    padding-top: 1px; //2.5px; //0.2em; // v13->v14 fix
    user-select: none;
}

.form-label.disabled, .form-check-label.disabled {
    opacity: 0.5;
}

.input-group input[type='datetime'] {
    min-width: 100px;
}

/* input type="search" styling the X clear button (implemented by the browsers) */
input[type="search"] {

    &::-webkit-search-cancel-button {
        -webkit-appearance: none; //hide the icon shown by the browser
        position: relative;
        right: -0.5em;
        height: 1.4em;
        width: 1.4em;
        border-radius: 50em;
        background: url('../assets/common/images/fa-times.svg') no-repeat 50% 50%;
        background-size: contain;
        opacity: 0.2;
        //pointer-events: none;
        transition: opacity ease-in-out 0.1s;
    }

    &:hover::-webkit-search-cancel-button {
        opacity: 0.3;
    }

    &:focus::-webkit-search-cancel-button {
        opacity: 0.3;
        //pointer-events: all;
    }
}


/* Forms */
.form-group {
    margin-bottom: 1.1em;
}

.max-form-width {
    max-width: 1200px;
}

/* Bootstrap buttons text vertical alignment */
.btn:not(.deleteWidgetButton):not(.btn-outline):not(.btn-dashed):not(.border-hover):not(.border-active):not(.btn-flush):not(.btn-icon).btn-sm, 
.btn-group-sm > .btn:not(.btn-outline):not(.btn-dashed):not(.border-hover):not(.border-active):not(.btn-flush):not(.btn-icon) {
    padding-top: 0.8em !important;
}

/* Bootstrap button with font-awesome icon */
button.btn > i.fa {
    padding-top: 0;
}

/* Bootstrap icon-only buttons (round) */
.btn.btn-icon.btn-icon-fab {
    border-radius: 50%;

    &:hover {
        color: $primary_dark;
        background-color: $colour-light-alt !important;
    }
}

/* Action Buttons with dropdown/chevron */
button:is([dropdowntoggle]) {
    white-space: nowrap;
}

/* Bootstrap input group field border next to button/icon */
.input-group:not(.has-validation) > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu),
.input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n+3) {
    border-right-width: 0;
}

.input-group > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
    border-left-width: 0;
}

.input-group .input-group-text {
    height: 40px;
}

/* Bootstrap input group button/icon */
.input-group i.fa,
.input-group i.pi {
    padding-left: 0.25em;
    padding-right: 0.25em;
}

.input-group i[class^="ds-icon-"],
.input-group i[class*=" ds-icon-"] {
    padding-left: 0;
    padding-right: 0;
    margin-left: 0;
    font-size: 1.1em;
}

/* Bootstrap dropdown/toggle */
.btn-group.dropdown {

    button {

        & > i[class^="ds-icon-"],
        & > i[class*=" ds-icon-"] {
            display: inline-block;
            font-size: 1em;
            margin-left: 0.4em !important;
            margin-right: 0 !important;
            transition: transform ease-in-out 0.2s;
        }
    }

    &.open.show button {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;

        i[class^="ds-icon-"],
        i[class*=" ds-icon-"] {
            transform: rotate(-180deg) !important;
        }
    }
}

.dropdown-menu {
    // border-width: 1px !important;
    border: solid 1px $light_hover;
    padding: 0;

    .dropdown-item {
        padding-top: 0.65em;
        padding-bottom: 0.65em;
        font-size: 1.1em !important;
        
        i[class^="ds-icon-"],
        i[class*=" ds-icon-"] {
            display: inline-block;
            position: relative;
            top: 2px;
            color: black;
            font-size: 1.2em !important;
            margin-left: 0 !important;
            margin-right: 0.5em !important;
        }

        &:active {
            color: black;
        }
    }

    li:first-child:hover .dropdown-item {
        border-top-right-radius: 4px;
    }

    li:last-child:hover .dropdown-item {
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
    }

    li:not(:first-child) .dropdown-item {
        border-top: solid 1px $light_hover;
    }

}

.dropdown.open.show .dropdown-menu.show {
    border-top-left-radius: 0;
    transform: translateY(-2px);
}


/* Button Groups */
.btn-group {
    display: inline-block;
    border: none;

    &:not(.btn-group-sm) {
        height: 40px;
    }

    
    padding: 0;
    //overflow: hidden;
    
    .btn {
        line-height: 1.15em;
        
        &.btn-outline-dark {
            border: solid 1px #b5b5c3 !important;
        }
    }
}

/* Multiple buttons in a div */
.button-strip {
    white-space: nowrap;

    button, input[type='button'], input[type='submit'] {
        transform: translateY(1px);
    }
}

/* slider switch (checkbox style) */
.form-switch {
    .form-check-input:checked {
        background-color: $primary;
        border-color: $primary;
    }
}

input[type='checkbox'] + label {
    margin-left: -6px;
    padding-left: 6px;
}


/* range slider */
// input[type='range'],
// input[type='range']::-webkit-slider-thumb,
// input[type='range']::-moz-range-thumb,
// input[type='range']::-ms-thumb {
.form-range {
    border: 0 !important;
    //color: $primary; //doesn't affect it...
}

/* BEGIN: badges */
.badge {
    display: inline-block;
    padding: 8px 10px 6px 10px;
    font-size: 14px;
    font-weight: normal;
    line-height: 1;
    color: #ffffff;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 18px;
}
/* END: badges */


/* BEGIN: primeng DataTable */

.p-datatable {

    &.main-list {
        font-size: 14px;
    }

    .p-datatable-table {
        //fix the table layout so column resizing works
        table-layout: fixed;
        width: 100%; // !important;
        min-width: 100%; // !important;
        max-width: 100%; // !important;

        border-collapse: collapse;
        //border-collapse: separate; //for radiused corners
        border: none;

        .p-datatable-thead {

            tr th {
                padding: 10px 10px 6px 10px;
                border-right: solid 1px transparent;
                border-bottom: none;
                border-left: none;
                background-color: transparent;

                &.ellipsis {
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    overflow: hidden;
                }
            }

            .p-sortable-column {
                padding-right: 28px; //to allow for sort icon

                .p-sortable-column-icon {
                    transition: color 0.1s ease-in-out;
                    margin-left: 0;
                    margin-right: 0;
                    position: absolute;
                    right: 10px; //to allow for resizer
                    top: 13px;
                }
                
                &:hover .p-sortable-column-icon {
                    color: #6eacfd;
                }
            }

            .p-resizable-column {

                .p-column-resizer {
                    border-left: dashed 1px transparent;
                    border-right: dashed 1px transparent;
                    transition: border-color 0.1s ease-in-out;
                }

                &:hover {
                    .p-column-resizer {
                        border-left: dashed 1px #CCCCCC;
                        border-right: dashed 1px #CCCCCC;
                    }
                }
            }

            .p-resizable-column {

                .p-column-resizer {
                    border-left: dashed 1px transparent;
                    border-right: dashed 1px transparent;
                    transition: border-color 0.1s ease-in-out;
                }

                &:hover {
                    .p-column-resizer {
                        border-left: dashed 1px #CCCCCC;
                        border-right: dashed 1px #CCCCCC;
                    }
                }
            }
        }

        .p-datatable-tbody tr {
            border: none;
            border-top-left-radius: 5px;
            border-bottom-left-radius: 5px;
            background-color: white;

            //add hover to datatable rows
            &:not(.norowclick):hover {
                cursor: pointer;
                background-color: #ECECEC !important; // $light_hover;
            }

            td {
                height: 39px;
                padding: 10px 10px 6px 10px;
                //padding: 11px 12px 8px 12px;
                border-top: solid 1px #ECECEC !important;
                border-right: solid 1px #ECECEC !important;
                border-bottom: none;
                border-left: none;
                vertical-align: middle;

                &:first-child {
                    border-left: solid 1px #ECECEC;
                    // border-top-left-radius: 5px;
                    // border-bottom-left-radius: 5px;
                }

                // &:last-child {
                //     border-top-right-radius: 5px;
                //     border-bottom-right-radius: 5px;
                // }

                &.ellipsis {
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    overflow: hidden;
                }
            }

            &:last-child td {
                border-bottom: solid 1px #ECECEC !important;
            }
        }
    }
}

.p-datatable-scrollable .p-datatable-wrapper {
    padding-bottom: 1em;
}

/*

.p-datatable.main-list {

    .p-datatable-table {

        .p-datatable-thead {
            margin-bottom: 0.25em;
            display: block; //v13->v14 fix

            tr {
                display: flex; //v13->v14 fix
                flex-wrap: nowrap; //v13->v14 fix
                width: 100%; //v13->v14 fix
            }

            tr th {
                display: flex; //v13->v14 fix
                flex: 1 1 0; //v13->v14 fix
                align-items: center; //v13->v14 fix

                &.ellipsis {
                    //display: block; //v13->v14 fix
                    //      padding-top: 3px; //v13->v14 fix
                    padding-top: 9px;
                    display: inline-block; //v13->v14 fix
                    background-color: grey;
                }
            }
        }



        .p-datatable-tbody tr {
            display: flex; //v13->v14 fix
            flex-wrap: nowrap; //v13->v14 fix
            width: 100%; //v13->v14 fix

            
        }

        // .p-datatable-tbody > tr > td, 
        // .p-datatable-thead > tr > th {
        //     border: none; //removes dashed row separator
        // }
    }
}



*/

/* END: primeng DataTable */


/* Scrollbars */
/* Works on Firefox */
span, ol, ul, pre, div {
    // scrollbar-width: thin !important;
    scrollbar-width: auto !important;
    scrollbar-color: #8C8C8C !important;
}

/* Works on Chrome, Edge, and Safari */
span::-webkit-scrollbar, ol::-webkit-scrollbar, ul::-webkit-scrollbar, pre::-webkit-scrollbar, div::-webkit-scrollbar {
    //width: 16px;
    width: 12px !important;
    height: 12px !important;
}

span::-webkit-scrollbar-thumb, ol::-webkit-scrollbar-thumb, ul::-webkit-scrollbar-thumb, pre::-webkit-scrollbar-thumb, div::-webkit-scrollbar-thumb {
    background-color: #8C8C8C !important;
}

/* Bootstrap tabs */
.nav-link {
    user-select: none;
}

.top-tab-bar, .nav-tabs {
    padding: 0; // 30px 0 30px;

    .nav-item {
        border-bottom: solid 2px transparent;
        //margin: 0.5em;
        margin: 4px 0 0 0;
        transition: border-color 0.15s ease-in-out;
        
        .nav-link {
            color: black;
            //padding: 1em 1.2em;
            //font-size: 1.1em;
            padding: 9px 20px;
            font-size: 16px;
            border: none;
            border-radius: 0;
            transition: all 0.15s ease-in-out;
            transition-property: color, background-color;

            &.active {
                font-weight: bold;
            }
            
            &:hover {
                color: black !important;
                background-color: $colour-light-alt;
                border: none;
            }

            &.active {
                color: black !important;
                border: none;
                background: none;
                border-bottom: solid 2px $primary;
            }

            &.active i, &:hover i {
                color: black;
            }

            &.disabled, &.disabled i {
                color: #C0C0C0 !important;
            }

            i.fa, i.pi,
            i[class^="ds-icon-"],
            i[class*=" ds-icon-"] {
                margin-right: 0.6em;
                //font-size: 1.1em;
                font-size: 16px;
                position: relative;
                top: 2px;
                color: black;
                transition: color 0.15s ease-in-out;
            }

            i[class^="ds-icon-"],
            i[class*=" ds-icon-"] {
                //font-size: 1.4em;
                //top: 4px;
                font-size: 21px;
                top: 3px;
                margin-right: 0.3em;
            }
        }
    }
}


/* Confirmation / Warning / Success popups */
.swal2-container {
    z-index: 99999;
    
    .swal2-popup.swal2-modal {
        padding: 1em;
    }
    
    .swal2-actions {
        margin-top: 2em;

        button {
            min-width: 100px;
            margin: 0 0.5em !important;
            font-size: 1.15em !important;
            padding-top: 0.55em !important;

            &.swal2-confirm {
                background-color: $primary;
                border: solid 1px $primary;

                &:hover {
                    background-color: $primary_dark;
                    border: solid 1px $primary_dark;
                }
            }

            &.swal2-cancel {
                background-color: $colour-universal-white;
                border: solid 1px $colour-dark-primary;
                color: $colour-dark-primary;

                &:hover {
                    background-color: $colour-dark-primary;
                    color: $colour-universal-white;
                }
            }
        }
    }
}



/* END: Styling corrections for Bootstrap, Metronic etc AFTER Volund styles */
/****************************************************************************/


/*******************************/
/* BEGIN: Bootstrap DatePicker */
.bs-datepicker {

    .bs-datepicker-container {
        padding: 0 !important;
    

        .bs-datepicker-head {
            background-color: $colour-light-alt !important;

            * {
                color: black !important;
            }

            button {
                line-height: 12px;

                // span {
                //     position: relative;
                //     top: -1px;
                // }
            }

            button.previous,
            button.next {
                padding: 0 !important;
            }

            button.previous:hover,
            button.next:hover {
                background-color: white !important;
                -webkit-box-shadow: 0px 2px 2px 2px rgba(99,99,99,0.1); 
                box-shadow: 0px 2px 2px 2px rgba(99,99,99,0.1);
            }

            button.current {
                // padding-top: 1px;
                // margin-top: 1px;

                &:hover {
                    border-radius: 4px;
                    background-color: white !important;
                    box-shadow: 0px 2px 2px 2px rgba(99,99,99,0.1);
                }
            }
        }

        .bs-datepicker-body {
            background-color: $colour-light-alt !important;
            
            table th {
                color: black;
            }

            table td {
                span {
                    background-color: white !important;
                    border: solid 1px $colour-light-alt !important;
                    border-radius: 4px !important;
                    color: black !important;
                
                    &.is-other-month {
                        background-color: $colour-light-alt !important;
                        color: $colour-dark-alt !important;
                        cursor: default;
                    }

                    &:not(.is-other-month):hover {
                        transition: border-color ease-in-out 0.15s;
                        border: solid 1px $primary !important;
                        color: black !important;
                    }

                    &.selected {
                        background-color: inherit !important;
                        border: solid 1px $colour-dark-alt !important;
                        color: $colour-dark-primary !important;

                        &:hover {
                            transition: border-color ease-in-out 0.2s;
                            border: solid 1px $primary !important;
                            color: black !important;
                        }
                    }
                }
            }
        }
    }
}


/* END: Bootstrap DatePicker */
/*****************************/

/****************************/
/* BEGIN: PrimeNG Paginator */

.p-paginator {
    background-color: transparent;

    .p-paginator-current {
        font-size: 16px;
        padding-top: 3px;
    }

    .p-paginator-pages .p-paginator-page,
    .p-paginator-first, 
    .p-paginator-prev, 
    .p-paginator-next, 
    .p-paginator-last {
        border-radius: 5px;
        font-size: 16px;
        margin: 0.143rem;

        &.p-highlight {
            background-color: $colour-dark-alt;
            color: white;
        }

        &:not(.p-highlight):hover {
            background-color: $colour-light-alt;
        }

        &.p-disabled {
            color: #A0A0A0 !important;
            border: none !important;
            //border-color: $colour-light-hover !important;
        }
    }

    .p-paginator-icon {
        text-indent: 0;
        color: black;
        font-size: 16px;
        transform: translateY(-1px);
    }

    .p-paginator-element {
        line-height: 12px;
    }

    .p-paginator-first .p-paginator-icon::before {
        font-family: icomoon;
        content: "\e959"; //ds-icon-default-double-chevron-left
    }

    .p-paginator-prev .p-paginator-icon::before {
        font-family: icomoon;
        content: "\e92d"; //ds-icon-default-chevron-left
    }

    .p-paginator-next .p-paginator-icon::before {
        font-family: icomoon;
        content: "\e92e"; //ds-icon-default-chevron-right
    }

    .p-paginator-last .p-paginator-icon::before {
        font-family: icomoon;
        content: "\e95a"; //ds-icon-default-double-chevron-right
    }
}

/* END: PrimeNG Paginator */
/**************************/

.p-disabled, .p-component:disabled {
    opacity: 1;
}

/* search input fields */
.search-field {
    max-width: 350px;

    &:focus + button {
        border-color: #B5B5C3 !important;
    }
}

/********************************/
// BEGIN: Header Bar
.header-fixed .header {
    overflow-y: hidden;
    background-color: white;
}

/* main logo styling */
// img.d-lg-inline.main-logo,
// .aside-logo img.logo {
//     width: 150px;
//     transform: translateX(-3px);
// }

// img.d-lg-none.main-logo {
//     // smaller screen sizes
//     width: 200px;
// }

.aside-logo {
    display: inline-block;
    background-color: #FCFCFC;

    .logo, .logo-min {
        position: absolute;
        top: 21px;
        left: 18px;
        height: 38px !important;
        width: auto;
        transition: opacity 0.25s ease-in-out;
        opacity: 0;
    }

    // .flex-column-auto {
    //     position: relative;
    //     overflow: hidden;
    // }
}

@media (max-width: 991.98px) {
    .logo, .logo-min {
        display: none;
    }
}

@media (min-width: 992px) {

    .aside:not(.aside-hoverable) .aside-logo,
    .aside:not(:hover) .aside-logo {
        .logo {
            display: inline;
            opacity: 1;
        }
        .logo-min {
            display: inline;
            opacity: 0;
        }
    }
    
    body[data-kt-aside-minimize=on] .aside:not(.aside-hoverable) .aside-logo,
    body[data-kt-aside-minimize=on] .aside:not(:hover) .aside-logo {
        .logo {
            display: inline;
            opacity: 0;
        }
        .logo-min {
            display: inline;
            opacity: 1;
        }
    }
}

.aside-toggle {
    //position: absolute;
    position: fixed; //stops the button from moving when the content window is scrolled
    top: 44px;
    //bottom: 100px;
    left: 245px;
    width: 40px;
    height: 40px;
    
    border-radius: 50%;
    text-align: center;
    line-height: 3.7em;
    background-color: white;
    box-shadow: 0px 2px 3px 3px rgba(127,127,127,0.1);
    z-index: 999;
    transition: all 0.15s ease-in-out;
    transition-property: background-color, color, box-shadow; //, line-height, padding-left;
    transition: opacity 0.3s ease-in;
    transition: left 0.25s linear;

    &.active {
        left: 55px;
    }

    i {
        font-size: 1.3em;
        color: #A0A0A0;
        display: inline-block;
        transition: transform 0.15s ease-in-out;
        position: relative;
        top: -0.5px;

        &.rotate-180 {
            // fix positioning due to icon symbol offset
            left: 1px;
            top: -1.5px;
        }
    }

    i.left-arrow {
        display: inline-block;
    }
    i.right-arrow {
        display: none;
        padding-left: 2px;
    }

    &:hover {
        background-color: #FCFCFC;
        box-shadow: 0px 2px 3px 3px rgba(99,99,99,0.1);

        i {
            color: black;
        }
    }

    &.active {
        // line-height: 3.45em;
        // padding-left: 1px;
        i.left-arrow {
            display: none;
        }
        i.right-arrow {
            display: inline-block;
        }
        // i {
        //     transform: rotate(-180deg) !important;
        // }
    }
}

@media (max-width: 991.98px) {
    .aside-toggle {
        // display: none;
        opacity: 0;
        
    }
}
// END: Header Bar
/********************************/


/********************************/
// BEGIN: Main Menu (side menu)
.menu .menu-footer {
    position: absolute;
    left: 3px;
    bottom: 0;
    max-height: 38px;
}

.aside {
    &.aside-light{
        background-color: #FCFCFC;
    }

    border-right: solid 1px #dddddd;

    .menu .menu-item > .menu-link {
        border-left: 5px solid transparent;
        //margin-right: 1.5em;
        padding-top: 11px;
        padding-bottom: 11px;
    }
}

.aside-menu {
    //padding-left: 1.5em;
    padding-left: 2px !important;
    background-color: #FCFCFC !important;

    .menu .menu-item .menu-link {
        padding-left: 1em;

        &.hassubmenu {
            padding-left: calc(1em + 5px);
        }
    }
}

.aside .menu .menu-item > .menu-link:not(.menu-toggle).active,
.aside .menu .menu-item a.menu-link:not(.menu-toggle).active {
    border-left: 5px solid $primary !important;
    // border-top-left-radius: 5px;
    // border-bottom-left-radius: 5px;
    background-color: $colour-light-alt;
}

.menu-title {
    padding-top: 0.2em;
    font-size: 1.2em;
}

.menu-state-title-primary .menu-item .menu-link.active .menu-title {
    color: black !important;
    font-weight: bold;
}

.menu-state-icon-primary .menu-item .menu-link.active .menu-icon i {
    //color: $primary !important;
    color: black !important;
}

.aside .menu .menu-item {
    // margin-bottom: 10px;

    .menu-icon {
        width: 2.6em;
        
        i {
            margin-left: auto;
            margin-right: auto;
        }

        i[class^="ds-icon-"],
        i[class*=" ds-icon-"] {
            font-size: 1.8em;
            position: relative;
            top: 0px;
            color: black !important;
        }
    }
}

.menu-state-title-primary .menu-item.hover > .menu-link:not(.disabled):not(.active), 
.menu-state-title-primary .menu-item .menu-link:hover:not(.disabled):not(.active) {
    //color: $primary !important;
    color: black !important;
    background-color: #F4F4F4;
    
    .menu-icon, .menu-icon i {
        //color: $primary !important;
        color: black !important;
    }

    .menu-title {
        //color: $primary !important;
        color: black !important;
    }
}

.sidemenu-submenu {
    min-width: 200px;
    transform: translate(262px, -44px);

    &.collapsed {
        transform: translate(72px, -44px);
    }

    .p-menu-list .p-menuitem .p-menuitem-link .p-menuitem-icon {
        font-size: 1.8em !important;
        color: black;
    }

    .p-menuitem-link {
        border-left: 5px solid transparent !important;
    }

    .p-menuitem-link-active {
        border-left: 5px solid $primary !important;
    }

    .p-menuitem-link .p-menuitem-text {
        font-size: 1.2em;
        padding-left: 4px;
    }
}

@media (max-width: 991px) {
    .sidemenu-submenu {
        min-width: 200px;
        transform: translate(247px, -44px);
    }
}

// .aside.drawer.drawer-on .aside-menu .menu .sidemenu-submenu {
//     border: solid 1px red !important;
//     transform: translate(234px, -44px);
// }

/* Cards */
.card {
    .card-header {
        background-color: $light;
        min-height: 60px;
    }

    .card-body {
        padding: 2em;
    }

    &.card-custom {
        background-color: white; //$colour-light-primary;

        .card-header {
            background-color: white;
        }
    }

    &.no-border {
        border: none;
        box-shadow: none;
    }
}
// END: Main Menu (side menu)
/********************************/


/********************************/
// BEGIN: Main Body
.page .body {
    background-color: white; //$colour-light-primary;
    position: relative;
}

// END: Main Body
/********************************/


/********************************/
// BEGIN: user dropdown menu
user-menu {
    /* this is an awkward one to style as the class property on the user-menu button is 
    an @input parameter that is specified in the metronic theme file */

    .menu {
        width: 300px;
    }

    &.button:first-child {
        border: 1px solid $light_additional !important;
        color: $dark !important;
    }

    .btn-check:checked + .btn:first-child.btn-active-light-primary, 
    .btn-check:active + .btn:first-child.btn-active-light-primary, 
    .btn:first-child.btn-active-light-primary:focus:not(.btn-active), 
    .btn:first-child.btn-active-light-primary:hover:not(.btn-active), 
    .btn:first-child.btn-active-light-primary:active:not(.btn-active), 
    .btn:first-child.btn-active-light-primary.active, 
    .btn:first-child.btn-active-light-primary.show, 
    .show > .btn:first-child.btn-active-light-primary {
        // hover styling
        background-color: $dark_additional !important;
        color: #ffffff !important;
    }
    
    // these menu-item colors need setting otherwise there is a brief flash of blue when leaving hover!
    .menu-state-primary .menu-item > .menu-link:not(.disabled):not(.active), 
    .menu-state-primary .menu-item .menu-link:not(.disabled):not(.active) {
        color: #3F4254 !important;
        transition: color ease 0.2s;
    }

    .menu-state-primary .menu-item.hover > .menu-link:not(.disabled):not(.active),
    .menu-state-primary .menu-item .menu-link:hover:not(.disabled):not(.active),
    .menu-state-primary .menu-item.hover > .menu-link:not(.disabled):not(.active) i, 
    .menu-state-primary .menu-item .menu-link:hover:not(.disabled):not(.active) i {
        color: $primary !important;
        transition: color ease 0.2s;
    }

    .menu-item {
        font-size: 0.93em;
    }

    .menu-item i.fa,
    .menu-item i.pi  {
        font-size: 1.15em;
    }
    
    .menu-item i[class^="ds-icon-"],
    .menu-item i[class*=" ds-icon-"] {
        font-size: 1.3em;
        margin-left: 0 !important;
        margin-right: 0.6em !important;
    }

    .menu-item i.smaller {
        font-size: 1em;
    }

    .card {
         .card-header {
            background-color: $light;
            padding: 0 1em;
        }

        .card-body {
            padding: 1em 2em;
        }

        .card-footer {
            padding: 1em 2em;
        }
    }
}

.menu-sub-dropdown.user-dropdown-menu.show {
    border: solid 1px $light_hover;
    transform: translate(-30px, 62px) !important;
    //padding-top: 0 !important;
}
// END: user dropdown menu
/********************************/


/********************************/
// BEGIN: Modal dialogs
.modal-dialog {

    .modal-content {

        .modal-header {
            //background-color: $light;
            background-color: white;
            border-bottom: none;

            .modal-title {
                margin: 0;
            }
        }

        .modal-footer {
            justify-content: space-between;
            border-top: none;

            button.ds {
                min-width: 90px;
            }
        }
    }
}

.modal-dimmed {
    filter: brightness(85%);
}
// END: Modal dialogs
/********************************/


/**************************/
// BEGIN: Dashboard Styles
button.deleteWidgetButton {
    //this is the close X button in the top-right
    padding: 0 !important;
    background-color: #E0E0E0 !important;

    &:hover {
        background-color: #D0D0D0 !important;
    }

    i {
        margin-left: -2px;
        color: #505050 !important;

        &:hover {
            color: #000000;
        }
    }
}
// END: Dashboard Styles
/**************************/


/* top-bar Page Title */
.top-bar-page-title {
    position: absolute;
    top: 1em;
    left: 1.5em;
    font-size: 1.5em;
    color: #181C32;

    @media (max-width: 991.98px) {
        top: 0.75em;
        left: 2.5em;
    }

    $colour-breadcrumb-link: #666666;

    .breadcrumb-container {
        display: inline-flex;
        margin-left: 1em;
        font-size: 14px;
        /* experimental wrapping:
        max-width: 600px;
        transform: translateY(-21px);
        line-height: 1.3em;
        align-content: center;
        */

        a {
            cursor: pointer;
            padding: 0 0.25em;
            color: $colour-breadcrumb-link;
            opacity: 0.8;
            // font-weight: bold;
            transition: opacity ease-in-out 0.2s;
            text-decoration: underline !important;

            &:hover {
                opacity: 1;
                text-decoration: underline !important;
                // background-color: #F0F0F0;
            }
        }

        span {
            padding: 0 0.25em;
            color: $colour-breadcrumb-link;
            opacity: 0.8;
            user-select: none;
        }

        $breadcrumb-padding: 2px;

        /* breadcrumb styles at top of page */
        .breadcrumb-dot .breadcrumb-item {
            padding: 0 $breadcrumb-padding 0 0;
            &:after {
                content: '/';
                color: $colour-breadcrumb-link;
                padding: 0 0 0 $breadcrumb-padding;
            }
        }
    }
}

.header-fixed.toolbar-fixed .wrapper {
    //padding-top: 0;
    //padding-top: calc(65px + var(--kt-toolbar-height));
    padding-top: calc(var(--kt-toolbar-height) + 10px);
}

.btn.btn-show-menu i {
    font-weight: bold;
    font-size: 1.3em;
    padding-top: 0.2em;
}

.page-toolbar {
    justify-content: flex-end;
    text-align: right;
    margin-right: 2em;
    margin-bottom: 1em;
}

.bb-grey {
    border-bottom: solid 1px #dddddd;
    
}

/* main layout footer */
.footer-links {
    
    li, a {
        color: #A1A5B7;
    }

    a:hover {
        color: $primary;
    }
    
}

/* BEGIN: PrimeNG Menu */
.p-menu {
    padding: 0;
    width: auto;

    ul {
        margin-bottom: 0;

        .p-menuitem-separator {
            margin: 0.1rem 0;
        }
    }

    .p-menu-list {
        padding-left: 0;

        .p-submenu-header {
            font-weight: bold;
            background-color: #F0F0F0;
            border-top-left-radius: 4px;
            border-top-right-radius: 4px;
        }

        .p-menuitem {
            .p-menuitem-link {
                transition: background-color ease-in-out 0.15s;

                .p-menuitem-icon {
                    color: black !important;
                    font-size: 17px;
                    min-width: 20px;
                }
            }

            &.p-disabled {
                .p-menuitem-icon, .p-menuitem-text {
                    opacity: 0.4;
                }
            }
        }

        /* BEGIN: angular upgrade v15 -> v16 fix */
        .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus > .p-menuitem-content {
            background-color: white; //otherwise, shows as mid-grey!

            &:hover {
                background-color: rgba(0, 0, 0, 0.04); //restore hover
            }
        }

        .p-menuitem-content:hover {
            background-color: rgba(0, 0, 0, 0.04); //restore hover
        }
        /* END: angular upgrade v15 -> v16 fix */
    }
}
/* END: PrimeNG Menu */

.btn-modal-help {
    border-radius: 50%;
    padding: 1em;
    width: 40px;
    height: 40px;
    text-align: center;
    box-sizing: border-box;
    background-color: white;
    border: none;
    padding: 0 !important;
    position: absolute;
    right: 60px;

    i {
        font-size: 24px;
        margin: 0;
        padding: 0;
        position: relative;
        top: 1px;
    }

    &:hover {
        background-color: $light_hover !important;
        transition: background-color ease-in-out 0.2s;

        i {
            color: black;
        }
    }

    &.right-0 {
        right: 0px;
    }
}

.cc-window.cc-banner {
    background-color: #333333;
    color: white;

    .cc-compliance .cc-btn {
        background-color: #FFE600;
        border-color: black;

        &:hover {
            color: #0000FF;
            border-color: #0000FF;
        }
    }
    
}

/* BEGIN: Styling fixes for Quill (PrimeNG Editor) */
.ql-snow.ql-toolbar button,
.ql-snow .ql-toolbar button {
    padding: 3px 5px !important;
}

.ql-snow.ql-toolbar button:hover, 
.ql-snow .ql-toolbar button:hover, 
.ql-snow.ql-toolbar button:focus, 
.ql-snow .ql-toolbar button:focus, 
.ql-snow.ql-toolbar button.ql-active, 
.ql-snow .ql-toolbar button.ql-active, 
.ql-snow.ql-toolbar .ql-picker-label:hover, 
.ql-snow .ql-toolbar .ql-picker-label:hover, 
.ql-snow.ql-toolbar .ql-picker-label.ql-active, 
.ql-snow .ql-toolbar .ql-picker-label.ql-active, 
.ql-snow.ql-toolbar .ql-picker-item:hover, 
.ql-snow .ql-toolbar .ql-picker-item:hover, 
.ql-snow.ql-toolbar .ql-picker-item.ql-selected, 
.ql-snow .ql-toolbar .ql-picker-item.ql-selected {
    color: #0066cc;

    &:hover {
        background-color: #C0C0C0;
    }
}

.p-editor-content .ql-container .ql-editor a {
    cursor: pointer;
}

.p-editor-container .ql-snow.ql-toolbar button.ql-active {
    border: solid 1px #C0C0C0;
}

.ql-snow .ql-tooltip.ql-editing {
    width: calc(100% - 40px);
    max-width: 600px;

    input[type=text] {
        width: calc(100% - 150px); //bit dodgy as we don't know how wide the translated "Enter Link" and "Save" text will be!
    }

    .ql-action {
        float: right;
        padding-right: 10px;
    }
}

//Quill v2 fix
.ql-editor ol,
.ql-editor ul {
  padding-left: 0.75em;
}

.ql-editor ol {
    list-style-type: none !important;

    li[data-list=bullet] > .ql-ui:before {
        //content: '\2022'; //original bullet
        //content: '•';
        content: none !important;
    }

    //hint: use the charmap windows program to find the unicode character for the bullet you want!
    li[data-list=bullet] {
        &:before {
            font-size: 1.21rem; //odd size, but just takes the anti-aliasing off the character!
        }

        &:before, &.ql-indent-4:before {
            content: '●';
            transform: translate(-2px, 0px);
        }

        &.ql-indent-1:before, &.ql-indent-5:before {
            content: '■';
            transform: translate(-2px, -1px);
        }

        &.ql-indent-2:before, &.ql-indent-6:before {
            content: '○';
            transform: translate(-2px, 0px);
        }

        &.ql-indent-3:before, &.ql-indent-7:before {
            content: '□';
            transform: translate(-2px, -1px);
        }
    }
}

/* END: Styling fixes for Quill (PrimeNG Editor) */

/* BEGIN: PrimeNG SpeedDial buttons */
$colour-speeddial-button: #707070;

.p-speeddial-button.p-button.p-button-icon-only {
    background-color: white;
    color: $colour-speeddial-button;

    .p-button-icon {
        color: $colour-speeddial-button;
        font-size: 1.5rem;
    }
}

.p-button.p-button-text:enabled:focus,
.p-button.p-button-outlined:enabled:focus {
    background-color: white;
}

.p-button.p-button-text:enabled:hover,
.p-button.p-button-outlined:enabled:hover {
    color: $colour-speeddial-button;
    background-color: #EFEFEF;
}

.p-speeddial-button.p-button.p-component {
    // background-color: #FFFFFF;
    color: $colour-speeddial-button;
    width: 3rem;
    height: 3rem;
    border: solid 1px $colour-speeddial-button !important;

    &:hover {
        border: solid 1px $colour-speeddial-button !important;
    }
}

.p-button.p-button-outlined {
    box-shadow: none;
}

.p-speeddial-action {
    width: 3rem;
    height: 3rem;
    opacity: 1;
    background: #EFEFEF;
    color: black;
    border: solid 1px #999999;

    &:hover {
        background: $colour-dark-alt;
        color: white;
        border: solid 1px $colour-dark-alt;
    }
}

.dashboard-edit-buttons {
    // this is here as the class selector wasn't being recognised on the customizable-dashboard.component
    transform: translate(-32px, -18px);

    .p-speeddial-list {
        background-color: white;
    }

    &:not(.p-speeddial-opened) .p-speeddial-list {
        // need this otherwise the container takes pointer events away
        height: 0;
        overflow-y: hidden;
    }
}
/* END: PrimeNG SpeedDial buttons */

/* red text appearing under form-field for validation warnings */
.form-control-feedback {
    font-size: 0.925rem;
    margin-top: 0.5rem;
}

/* BEGIN: bootstrap dropdown container fix when used in modal popups */
bs-dropdown-container {
    z-index: 9999 !important;
    top: 2px !important;
    left: 1px !important;
}
/* END: bootstrap dropdown container fix when used in modal popups */

/* BEGIN: PerfectScrollbar */
.menu-scroll-container{
    position: relative; //this property is important
}
/* END: PerfectScrollbar */

/* BEGIN: Super-Tooltip Styles */
.tasknote-tooltip {
    padding: 0;
    display: block;
    width: auto !important;
    border: none;
    min-width: 280px;
    min-height: 150px;
    max-width: unset;

    &.tooltip-right, &.tooltip-left, &.tooltip-top, &.tooltip-bottom {
        .p-tooltip-text { border-radius: 10px; }
        .p-tooltip-arrow { display: inline-block !important; }
    }

    &.tooltip-top-right, &.tooltip-top-left, &.tooltip-bottom-right, &.tooltip-bottom-left {
        .p-tooltip-arrow { display: none !important; }
    }

    &.tooltip-top-right .p-tooltip-text { border-bottom-left-radius: 0; }
    &.tooltip-top-left .p-tooltip-text { border-bottom-right-radius: 0; }
    &.tooltip-bottom-right .p-tooltip-text { border-top-left-radius: 0; }
    &.tooltip-bottom-left .p-tooltip-text { border-top-right-radius: 0; }

    .p-tooltip-text {
        display: inline-block;
        margin: 0 4px;
        padding: 15px;
        overflow: hidden;
        border-radius: 10px;
        border: solid 1px #666666;
        transform: translate(0px, 1.5px);
        
        table.tasknote-tooltip-content {
            width: 100%;
            margin: 0;
            border-collapse: collapse;
            border: none;

            tr {
                border-bottom: dotted 1px #999999;

                td {
                    width: 120px;
                    min-width: 120px;
                    max-width: 120px;
                    vertical-align: top;
                    user-select: none;

                    &.changed {
                        //color: rgb(185 227 255); //mid-blue
                        // color: rgb(255 216 128); //golden
                        color: rgb(128 228 255); //light-blue
                        // color: rgb(255 158 158); //pinkish
                    }
                }

                td:first-child {
                    font-weight: bold;
                }

                &:first-child td:first-child {
                    font-size: 1.25rem;
                }
            }
        }
    }
}
/* END: Super-Tooltip Styles */
