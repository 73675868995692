subscription-notification-bar,
active-delegated-users-combo,
quick-theme-selection,
language-switch-dropdown,
header-notifications,
chat-toggle-button,
user-menu{
    display: flex;
}

#notificationIcon,
#chatIconUnRead{
    position: relative;
}

.theme-selection-link {
    cursor: pointer;
}

.dropdown-language .navi-link i.famfamfam-flags {
    display: inline-block;
}


header-notifications .dropdown-menu{
    max-width: 400px;
}

body.aside-minimize #kt_brand {
    width: 70px;
  }

.p-paginator {
    justify-content: flex-end !important;
}

.dropdown-menu-lg{
    min-width: 410px !important;
}

.swal2-popup .swal2-icon{
    margin: 1.25em auto 1.875em !important;
}


/** METRONIC 8 **/
.aside .menu-item .menu-icon i{
    font-size: 16px;
    line-height: 1;
}

.aside-menu .menu .menu-sub:not([data-popper-placement]) .menu-item .menu-link{
    padding-left: calc(0.75rem + 25px);
}
