/* asap-condensed-regular - latin_latin-ext */
@font-face {
  font-family: 'Asap Condensed';
  font-style: normal;
  font-weight: 400;
  src: url('./AsapCondensed/asap-condensed-v2-latin_latin-ext-regular.eot'); /* IE9 Compat Modes */
  src: local('Asap Condensed Regular'), local('AsapCondensed-Regular'),
       url('./AsapCondensed/asap-condensed-v2-latin_latin-ext-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./AsapCondensed/asap-condensed-v2-latin_latin-ext-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('./AsapCondensed/asap-condensed-v2-latin_latin-ext-regular.woff') format('woff'), /* Modern Browsers */
       url('./AsapCondensed/asap-condensed-v2-latin_latin-ext-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./AsapCondensed/asap-condensed-v2-latin_latin-ext-regular.svg#AsapCondensed') format('svg'); /* Legacy iOS */
}
/* asap-condensed-500 - latin_latin-ext */
@font-face {
  font-family: 'Asap Condensed';
  font-style: normal;
  font-weight: 500;
  src: url('./AsapCondensed/asap-condensed-v2-latin_latin-ext-500.eot'); /* IE9 Compat Modes */
  src: local('Asap Condensed Medium'), local('AsapCondensed-Medium'),
       url('./AsapCondensed/asap-condensed-v2-latin_latin-ext-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./AsapCondensed/asap-condensed-v2-latin_latin-ext-500.woff2') format('woff2'), /* Super Modern Browsers */
       url('./AsapCondensed/asap-condensed-v2-latin_latin-ext-500.woff') format('woff'), /* Modern Browsers */
       url('./AsapCondensed/asap-condensed-v2-latin_latin-ext-500.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./AsapCondensed/asap-condensed-v2-latin_latin-ext-500.svg#AsapCondensed') format('svg'); /* Legacy iOS */
}